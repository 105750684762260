<template>
  <div class="app-container">
    <div style="display: flex;align-items:center;text-align:center;margin-bottom: 10px;">
      <img :src="sendman.avatar" class="user-avatar">
      <span style="margin-left: 10px">{{ sendman.name }}</span>
      <el-button size="mini" type="primary" @click="handleSettle" style="margin-left: 20px">结算</el-button>
    </div>
    <div class="count-data">
      <div class="count-data-title">配送服务:</div>
      <div class="count-data-item">
        <div class="item-label">已结算数量</div>
        <div class="item-value">{{ count.send_number }}</div>
      </div>
      <div class="count-data-item">
        <div class="item-label">已结算金额</div>
        <div class="item-value">{{ count.send_price }}</div>
      </div>
      <div class="count-data-item">
        <div class="item-label">未结算数量</div>
        <div class="item-value">{{ count.send_number_unsettle }}</div>
      </div>
      <div class="count-data-item">
        <div class="item-label">未结算金额</div>
        <div class="item-value">{{ count.send_price_unsettle }}</div>
      </div>
    </div>
    <div class="count-data">
      <div class="count-data-title">投餐服务:</div>
      <div class="count-data-item">
        <div class="item-label">已结算数量</div>
        <div class="item-value">{{ count.meal_number }}</div>
      </div>
      <div class="count-data-item">
        <div class="item-label">已结算金额</div>
        <div class="item-value">{{ count.meal_price }}</div>
      </div>
      <div class="count-data-item">
        <div class="item-label">未结算数量</div>
        <div class="item-value">{{ count.meal_number_unsettle }}</div>
      </div>
      <div class="count-data-item">
        <div class="item-label">未结算金额</div>
        <div class="item-value">{{ count.meal_price_unsettle }}</div>
      </div>
    </div>
    <div class="count-data">
      <div class="count-data-title">第三方投餐服务:</div>
      <div class="count-data-item">
        <div class="item-label">已结算数量</div>
        <div class="item-value">{{ count.out_meal_number }}</div>
      </div>
      <div class="count-data-item">
        <div class="item-label">已结算金额</div>
        <div class="item-value">{{ count.out_meal_price }}</div>
      </div>
      <div class="count-data-item">
        <div class="item-label">未结算数量</div>
        <div class="item-value">{{ count.out_meal_number_unsettle }}</div>
      </div>
      <div class="count-data-item">
        <div class="item-label">未结算金额</div>
        <div class="item-value">{{ count.out_meal_price_unsettle }}</div>
      </div>
    </div>


    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row style="margin-top: 10px">
      <el-table-column label="序号" min-width="60" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="姓名" min-width="100" align="center">
        <template slot-scope="scope">
          {{ sendman.name }}
        </template>
      </el-table-column>
      <el-table-column label="配送服务结算" min-width="140" align="center">
        <el-table-column label="数量" align="center">
          <template slot-scope="scope">
            {{ scope.row.send_number }}
          </template>
        </el-table-column>
        <el-table-column label="金额" align="center">
          <template slot-scope="scope">
            {{ scope.row.send_price }}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="投餐服务结算" min-width="140" align="center">
        <el-table-column label="数量" align="center">
          <template slot-scope="scope">
            {{ scope.row.meal_number }}
          </template>
        </el-table-column>
        <el-table-column label="金额" align="center">
          <template slot-scope="scope">
            {{ scope.row.meal_price }}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="第三方投餐服务结算" min-width="140" align="center">
        <el-table-column label="数量" align="center">
          <template slot-scope="scope">
            {{ scope.row.out_meal_number }}
          </template>
        </el-table-column>
        <el-table-column label="金额" align="center">
          <template slot-scope="scope">
            {{ scope.row.out_meal_price }}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="结算时间" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.created_at }}
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      list: null,
      total: null,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        school_id: "",
        sendman_id: ""
      },
      btnLoading: false,
      form: {
        id: "",
        school_id: "",
        canteen_name: ""
      },
      count: {},
      sendman: {},
    };
  },
  created() {
    this.listQuery.sendman_id = this.$route.query.sendman_id || 0
    this.getList()
  },
  mounted() {},
  computed: {
    ...mapGetters(['schools','school_id']),
  },
  watch: {
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id
      this.listLoading = true;
      request({
        url: "/api/backend/sendmanSettle/list",
        method: "post",
        data: this.listQuery
      }).then(response => {
        this.list = response.data.list.data;
        this.total = response.data.list.total;
        this.sendman = response.data.sendman
        this.count = response.data.count
        this.listLoading = false;
      });
    },
    handleSettle() {
      this.$confirm('确定要结算, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.btnLoading = true;
        request({
          url: "/api/backend/sendmanSettle/settle",
          method: "post",
          data: {
            sendman_id: this.listQuery.sendman_id
          }
        }).then(response => {
          this.getList();
          this.btnLoading = false;
        });
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
.count-data {
  width: 100%;
  background: #f6f6f6;
  padding: 15px 0;
  display: flex;
  .count-data-title {
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
  }
  .count-data-item {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    .item-label {
      color: #666;
    }
    .item-value {
      color: #232323;
      font-weight: bold;
      margin-top: 5px;
    }
  }
}
</style>
